

.loader2 {
	background-color: #1111114d;
	overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  align-items: start;
  align-content: center; 
  justify-content: flex-start;  
	z-index: 100000;
}
 

 


.an_animation{
  position: absolute;
  top: 0;   
  left: 0;
  width: 100%;  
  height: 5px; 
   overflow: hidden;
}

.an_animation span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0b90b;
  background: linear-gradient(270deg, #ec9714 0%, #dcbe22 100%) !important;
  display: inline-block;
  animation: animate 10s infinite linear;
}

@keyframes animate{
   0%{
      transform: translateX(-100%);
   }
   50%{
       transform: translateX(100%);
    }
   100%{
       transform: translateX(-100%);
    }
}


